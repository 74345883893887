@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'OCR A Std Regular';
    src: local('OCR A Std Regular'), url(./assets/fonts/OCR_A_Std_Regular.ttf) format('truetype');
}

h1, h2, h3, h4, h5, h6 {
    font-family: "OCR A Std Regular";
}

ol.nested {
    counter-reset: item;
}

li.nested {
    display: block;
}

li.nested:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
}

[type='checkbox']:checked,
[type='radio']:checked,
.dark [type='checkbox']:checked,
.dark [type='radio']:checked {
    border-color: transparent;
    background-color: #990000;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.player-wrapper {
    width: auto; 
    height: auto; 
}

.react-player {
    padding-top: 56.25%; 
    position: relative; 
}

.react-player>div {
    position: absolute; 
}

:root[data-theme="light"] {
    color-scheme: light;
    color: black;
}
:root[data-theme="dark"] {
    color-scheme: dark;
    color: black;
}